export default {
    methods: {
        getActsShutters(props) {
            return {
                // 'contract-a-valider': {
                //     name: 'contract-a-valider',
                //     title: this.$t('monte.a_valider'),
                //     cmpPath: 'components/Contract/AValider',
                //     onOk: this.onShuttersSubmit,
                //     props
                // },
                'contract-a-valider': {
                    name: 'contract-a-valider',
                    title: this.$t('monte.a_valider'),
                    cmpPath: 'components/Contract/AValiderv2',
                    onOk: this.onShuttersSubmit,
                    props
                },
                // 'contract-form': {
                //     name: 'contract-form',
                //     title: this.$t('monte.modifier_contrat'),
                //     cmpPath: 'components/Contract/ContractForm',
                //     onOk: this.onShuttersSubmit,
                //     props
                // },
                'contract-form': {
                    name: 'contract-form-v2',
                    title: this.$t('monte.modifier_contrat'),
                    cmpPath: 'components/Contract/ContractFormv2',
                    onOk: this.onShuttersSubmit,
                    props
                },
                // 'contract-bons-valider': {
                //     name: 'contract-bons-valider',
                //     title: this.$t('monte.bons_facturer'),
                //     cmpPath: 'components/Invoice/Bons',
                //     onOk: this.onShuttersSubmit,
                //     props
                // },
                'contract-bons-valider': {
                    name: 'contract-bons-valider-v2',
                    title: this.$t('monte.bons_facturer'),
                    cmpPath: 'components/Invoice/Bonsv2',
                    onOk: this.onShuttersSubmit,
                    props
                },
                'contract-invoices': {
                    name: 'contract-invoices',
                    title: this.$t('monte.factures'),
                    cmpPath: 'components/Invoice/List',
                    onOk: this.onShuttersSubmit,
                    props
                },
                'contract-historique': {
                    name: 'contract-historique',
                    title: this.$t('monte.historique'),
                    cmpPath: 'components/Contract/ContractHistorique',
                    onOk: this.onShuttersSubmit,
                    props
                },
                'ajout-acte-poulinage': {
                    name: 'ajout-acte-poulinage',
                    title: this.$t('monte.create_acte'),
                    cmpPath: 'components/WriteActe',
                    onOk: this.onShuttersSubmitPoulinage,
                    props
                },
                'ajout-acte-dg': {
                    name: 'ajout-acte-dg',
                    title: this.$t('monte.create_acte'),
                    cmpPath: 'components/WriteActe',
                    onOk: this.onShuttersSubmitDG,
                    props
                },
                'ajout-acte-saillie' : {
                    name: 'ajout-acte-saillie',
                    title: this.$t('monte.create_acte'),
                    cmpPath: 'components/WriteActe',
                    onOk: this.onShuttersSubmitSaillie,
                    props
                },
                'courrier-type': {
                    name: 'courrier-type',
                    title: this.$t('courrier_type.title'),
                    cmpPath: 'components/Courrier/Contract',
                    props
                },
                'courrier-type-ajout': {
                    name: 'courrier-type-ajout',
                    title: this.$t('courrier_type.ajout_title'),
                    cmpPath: 'components/Courrier/ContractEdit',
                    props
                },
                'courrier-type-edit': {
                    name: 'courrier-type-edit',
                    title: this.$t('courrier_type.edit_title'),
                    cmpPath: 'components/Courrier/ContractEdit',
                    props
                },
                'contract-document': {
                    name: 'contract-document',
                    title: this.$t('monte.documents'),
                    cmpPath: 'components/Contract/ContractDocument',
                    props
                },
                'contract-list': {
                    name: 'contract-list',
                    title: this.$t('global.contracts'),
                    cmpPath: 'components/Contract/ContractListv2',
                    noClass: true
                },
                'contract-trackings': {
                    name: 'contract-trackings',
                    title: this.$t('global.tracking_emails'),
                    cmpPath: 'components/Trackings',
                    props  
                }
            }
        },
        setupContractForm(contract_id, from) {
            const shutters = this.getActsShutters({
            	contract_id,
                from
            })

            this.shutterPanel().open(shutters['contract-form'])
        },

        setupContractAValider(contract_id, horse_id, processing) {
            if(processing){
                this.failureToast("monte.wait_sync")
                return false
            }
            if(this.shutterPanel().isOpen('contract-a-valider')) {
                this.shutterPanel().close('contract-a-valider')
                this.shutterPanel().close('contract-bons-valider')
                this.shutterPanel().close('contract-invoices')
                this.shutterPanel().close('contract-historique')

                return false
            }

            if(this.shutterPanel().isOpen('contract-historique')) {
                this.shutterPanel().close('contract-historique')
            }

            contract_id = this.$sync.replaceWithReplicated('contract', contract_id)

            const shutters = this.getActsShutters({
            	contract_id,
            	horse_id,
            })
            this.shutterPanel().open(shutters['contract-a-valider'])
        },
        setupContractAValiderV2(contract_id, horse_id, processing) {
            if(processing){
                this.failureToast("monte.wait_sync")
                return false
            }
            if(this.shutterPanel().isOpen('contract-a-valider')) {
                this.shutterPanel().close('contract-a-valider')
                this.shutterPanel().close('contract-bons-valider')
                this.shutterPanel().close('contract-invoices')
                this.shutterPanel().close('contract-historique')

                return false
            }

            if(this.shutterPanel().isOpen('contract-historique')) {
                this.shutterPanel().close('contract-historique')
            }

            contract_id = this.$sync.replaceWithReplicated('contract', contract_id)

            const shutters = this.getActsShutters({
            	contract_id,
            	horse_id,
            })
            this.shutterPanel().open(shutters['contract-a-valider'])
        },
        setupContractBons(contract_id) {
            if(this.shutterPanel().isOpen('contract-bons-valider')) {
                this.shutterPanel().close('contract-bons-valider')
                this.shutterPanel().close('contract-invoices')
                return false
            }

            contract_id = this.$sync.replaceWithReplicated('contract', contract_id)


            const shutters = this.getActsShutters({
            	options: {
            		type: 'Contract',
            		id: contract_id
            	}
            	
            })
            this.shutterPanel().open(shutters['contract-bons-valider'])
        },
        setupContractBonsV2(contract_id) {
            if(this.shutterPanel().isOpen('contract-bons-valider')) {
                this.shutterPanel().close('contract-bons-valider')
                this.shutterPanel().close('contract-invoices')
                return false
            }

            contract_id = this.$sync.replaceWithReplicated('contract', contract_id)


            const shutters = this.getActsShutters({
            	options: {
            		type: 'Contract',
            		id: contract_id
            	}
            	
            })
            this.shutterPanel().open(shutters['contract-bons-valider'])
        },

        setupContractInvoices(contract_id) {
            if(this.shutterPanel().isOpen('contract-invoices')) {
                this.shutterPanel().close('contract-invoices')
                return false
            }
            contract_id = this.$sync.replaceWithReplicated('contract', contract_id)
            
            const shutters = this.getActsShutters({
                options: {
                    type: 'Contract',
                    id: contract_id
                }
                
            })
            this.shutterPanel().open(shutters['contract-invoices'])
        },

        setupViewHistorique(contract_id) {
            if(this.shutterPanel().isOpen('contract-a-valider')) {
                this.shutterPanel().close('contract-a-valider')
                this.shutterPanel().close('contract-bons-valider')
                this.shutterPanel().close('contract-invoices')
            }

            contract_id = this.$sync.replaceWithReplicated('contract', contract_id)

            const shutters = this.getActsShutters({
                contract_id
            })

            this.shutterPanel().open(shutters['contract-historique'])
        },

        setupAddPoulinage(horse_id) {
            this.shutterPanel().keepFirst()
            const shutters = this.getActsShutters({
                horses_ids: [horse_id],
                actes_ids: [],
                group_preselect: 'REPRO',
                type_preselect: 'REPRO_POULINAGE',
            })

            this.shutterPanel().open(shutters['ajout-acte-poulinage'])
        },

        setupAddDG(horse_id) {
            this.shutterPanel().keepFirst()
            const shutters = this.getActsShutters({
                horses_ids: [horse_id],
                actes_ids: [],
                group_preselect: 'REPRO',
                type_preselect: 'REPRO_DIAGNOSTIC_DE_GESTATION',
            })

            this.shutterPanel().open(shutters['ajout-acte-dg'])
        },

        setupAddSaillie(horse_id) {
            this.shutterPanel().keepFirst()
            const shutters = this.getActsShutters({
                horses_ids: [horse_id],
                actes_ids: [],
            })

            this.shutterPanel().open(shutters['ajout-acte-saillie'])
        },

        setupCourrierType(contract_id) {
            if(this.shutterPanel().isOpen('courrier-type')) {
                this.shutterPanel().close('courrier-type')
                this.shutterPanel().close('courrier-type-ajout')
                this.shutterPanel().close('courrier-type-edit')
                return false
            }

            contract_id = this.$sync.replaceWithReplicated('contract', contract_id)

            const shutters = this.getActsShutters({
                contract_id
            })

            this.shutterPanel().open(shutters['courrier-type'])
        },

        setupCourrierTypeAjout(contract_id) {
            if(this.shutterPanel().isOpen('courrier-type-ajout')) {
                this.shutterPanel().close('courrier-type-ajout')
                this.shutterPanel().close('courrier-type-edit')
                return false
            }

            contract_id = this.$sync.replaceWithReplicated('contract', contract_id)

            const shutters = this.getActsShutters({
                contract_id
            })

            this.shutterPanel().open(shutters['courrier-type-ajout'])
        },

        setupCourrierTypeEdit(contract_id, model_id, modeltype_template) {
            if(this.shutterPanel().isOpen('courrier-type-edit')) {
                this.shutterPanel().close('courrier-type-edit')
                this.shutterPanel().close('courrier-type-ajout')
                return false
            }

            contract_id = this.$sync.replaceWithReplicated('contract', contract_id)

            const shutters = this.getActsShutters({
                contract_id,
                model_id,
				modeltype_template
            })

            this.shutterPanel().open(shutters['courrier-type-edit'])
        },

        setupDocument(contract_id) {
            if(this.shutterPanel().isOpen('contract-document')) {
                this.shutterPanel().close('contract-document')
                return false
            }

            contract_id = this.$sync.replaceWithReplicated('contract', contract_id)

            const shutters = this.getActsShutters({
                contract_id
            })

            this.shutterPanel().open(shutters['contract-document'])
        },

        setupShareDocument(contract_id, type) {
            if(this.shutterPanel().isOpen('contract-document')) {
                this.shutterPanel().close('contract-document')
                return false
            }

            contract_id = this.$sync.replaceWithReplicated('contract', contract_id)

            const shutters = this.getActsShutters({
                contract_id,
                type
            })

            this.shutterPanel().open(shutters['contract-document'])
        },

        setupContractList() {
            const shutters = this.getActsShutters({})
            this.shutterPanel().open(shutters['contract-list'])
        },

        async openTrackingsShutter(contract_id) {
            const shutters = this.getActsShutters({ contract_id })
            this.shutterPanel().open(shutters['contract-trackings'])
        }
    }
}