import Constants from '../../config.local.js'
import Common from '@/assets/js/common.js'

var ModelMixin = {
	data() {
		return {

		}
	},
	methods: {
		loadRelationModels: async function(modelable_id, modelable_type) {
			const url = this.constructRoute(Constants.MODEL_URL, {modelable_id: modelable_id, modelable_type: modelable_type}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("ModelMixin::loadAllModel", url)
			.catch(error => {
				console.error("ModelMixin::loadAllModel => ERROR", error)
				return null
			})
			return result.retour || null
		},

		loadAllModel: async function(modelable_id, modelable_types = ['accounting_plan'], modeltype_template=null) {
			// On récupère le nouvel ID
			if (modelable_id < 0 && modelable_types.length === 1) {
				modelable_id = this.$sync.replaceWithReplicated(modelable_types[0], modelable_id)
			}

			// Si la ligne n'est toujours pas synchro
			if (modelable_id < 0 && modelable_types.length === 1) {
				await this.$sync.force()
				await this.$sync.runOptionalWhishlist([modelable_types[0]])
				modelable_id = this.$sync.replaceWithReplicated(modelable_types[0], modelable_id)
			}

			// Chargement de tous les types de modelable
			let models = []
			const promises = []
			modelable_types.forEach(modelable_type => {
				promises.push(
					this.loadRelationModels(modelable_id, modelable_type)
					.then(relationModels => {
						if(modeltype_template) {
							relationModels = [...relationModels].filter(rm => rm.type.modeltype_template == modeltype_template)
						}
						models = models.concat(relationModels)
					})
				)
			})

			await Promise.all(promises)

			return models
		},

		loadAllFromAddress: async function(){
			const url = Constants.MODEL_FROM_ADDRESS_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("ModelMixin::loadAllFromAddress", url)
			.catch(error => {
				console.error("ModelMixin::loadAllFromAddress => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		isLinkModelTiers: async function(modelable_id, tiers_id, modelable_type = 'accounting_plan') {
			const url = this.constructRoute(Constants.MODEL_TIERS_URL, {modelable_id: modelable_id, tiers_id: tiers_id, modelable_type: modelable_type}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("ModelMixin::isLinkModelTiers", url)
			.catch(error => {
				console.error("ModelMixin::isLinkModelTiers => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		loadModel: async function(modelable_id, model_id, modelable_type = 'accounting_plan') {
			const url = this.constructRoute(Constants.MODEL_DETAILS_URL, {modelable_id: modelable_id, model_id: model_id, modelable_type: modelable_type}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("ModelMixin::loadModel", url)
			.catch(error => {
				console.error("ModelMixin::loadModel => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		loadModelWithoutType: async function(model_id) {
			const url = this.constructRoute(Constants.MODEL_WITHOUT_TYPE_URL, {model_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("ModelMixin::loadModelWithoutType", url)
			.catch(error => {
				console.error("ModelMixin::loadModelWithoutType => ERROR", error)
				return null
			})
			if(result.retour) return result.retour
			return null 
		},

		loadTemplate: async function(modelable_id, model_id, modelable_type = 'accounting_plan', lang = 'fr') {
			const url = this.constructRoute(Constants.MODEL_TEMPLATE_URL, {modelable_id: modelable_id, model_id: model_id, modelable_type: modelable_type}) + `?lang=${lang}&licence_key=${Constants.USER_LICENCE_KEY}`
			const result = await this.$request.request_get_api("ModelMixin::loadTemplate", url)
			.catch(error => {
				console.error("ModelMixin::loadTemplate => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},


		updateModelTemplate: async function(modelable_id, model_id, modeltemplate_id, params, modelable_type = 'accounting_plan') {
			const url = this.constructRoute(Constants.MODEL_TEMPLATE_UPDATE_URL, {modelable_id: modelable_id, model_id: model_id, modeltemplate_id: modeltemplate_id, modelable_type: modelable_type}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("ModelMixin::updateModelTemplate", url, params, false)
			.catch(error => {
				console.error("ModelMixin::updateModelTemplate => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},
		
		duplicate_model: async function(model_id, model_label) {
			const url = this.constructRoute(Constants.MODEL_DUPLICATE_URL, {model_id: model_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("ModelMixin::duplicate_model", url, {model_label: model_label}, false, {withDetails: true})
			.catch(error => {
				console.error("ModelMixin::duplicate_model => ERROR", error)
				return {retour : {error: true, message: error.data.message}}
			})

			if(result) return result.retour
			return null
		},

		updateModel: async function(modelable_id, model_id, params, modelable_type = 'accounting_plan') {
			const url = this.constructRoute(Constants.MODEL_UPDATE_URL, {modelable_id: modelable_id, model_id: model_id, modelable_type: modelable_type }) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("ModelMixin::updateModel", url, params, false)
			.catch(error => {
				console.error("ModelMixin::updateModel => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		loadModelType: async function(modelable_types) {
			const url = Constants.MODEL_TYPE_URL + modelable_types.join(',') + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("ModelMixin::loadModelType", url)
			.catch(error => {
				console.error("ModelMixin::loadModelType => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},


		createModel: async function(modelable_id, modeltype_id, model_label, modelable_type = 'accounting_plan') {
			const url = this.constructRoute(Constants.MODEL_URL, { modelable_id: modelable_id, modelable_type: modelable_type }) + "?licence_key="+Constants.USER_LICENCE_KEY

			let params = { modeltype_id: modeltype_id, model_label: model_label }
			const result = await this.$request.request_post_api("ModelMixin::createModel", url, params, false)
			.catch(error => {
				console.error("ModelMixin::createModel => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},


		saveParametersTiers: async function(tiers_id, params) {
			const url = this.constructRoute(Constants.TIERS_PARAMETERS_URL, { tiers_id: tiers_id }) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("ModelMixin::saveParametersTiers", url, params, true)
			.catch(error => {
				console.error("ModelMixin::saveParametersTiers => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},
		
		getVariables: async function(modeltype_id) {
			const url = this.constructRoute(Constants.MODEL_TYPE_VARIABLES_URL, { modeltype_id: modeltype_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("ModelMixin::getVariables", url)
			.catch(error => {
				console.error("ModelMixin::getVariables => ERROR", error)
				return null
			})
			if(result) {
				return {
					variables: this.translateVariables(result.retour.variables),
					blocks: this.translateBlocks(result.retour.blocks),
				}
			}
			return null
		},

		cloneModel: async function(model_id, copy_id) {
			const url = this.constructRoute(Constants.MODEL_CLONE_URL, {model_id, copy_id}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("ModelMixin::cloneModel", url)
			.catch(error => {
				console.error("ModelMixin::cloneModel => ERROR", error)
				return null
			})
			if(result) {
				if(result.retour) return result.retour
			}
			return null
		},

		setDefaultCaModelContract: async function(model_id, contractconfig_id) {
			const url = this.constructRoute(Constants.MODEL_CONTRACT_CA_DEFAULT_URL, {model_id, contractconfig_id}) + "?licence_key="+Constants.USER_LICENCE_KEY

			return this.$request.request_post_api("ModelMixin::setDefaultCaModelContract", url, { model_id, contractconfig_id }, false)
				.then(res => res.retour)
				.catch(error => {
					console.error("ModelMixin::setDefaultCaModelContract => ERROR", error)
					return null
				})
		},

		setDefaultCaModelSeason: async function(model_id, season_id) {
			const url = this.constructRoute(Constants.MODEL_CONTRACT_CA_SEASON_DEFAULT_URL, {model_id, season_id}) + "?licence_key="+Constants.USER_LICENCE_KEY

			return this.$request.request_post_api("ModelMixin::setDefaultCaModelSeason", url, { model_id, season_id }, false)
				.then(res => res.retour)
				.catch(error => {
					console.error("ModelMixin::setDefaultCaModelSeason => ERROR", error)
					return null
				})
		},

		generateCourrier: async function(model_id, modelable_type, modelable_id, start_date = null, end_date = null, locale = null) {
			const params = {start_date, end_date}
			let localeString = locale ? "&locale=" + locale : ""

			const url = this.constructRoute(Constants.MODEL_GENERATE_URL, {model_id, modelable_type, modelable_id}) + "?licence_key="+Constants.USER_LICENCE_KEY + localeString

			const result = await this.$request.request_post_api("ModelMixin::cloneModel", url, params, false)
			.catch(error => {
				console.error("ModelMixin::cloneModel => ERROR", error)
				return null
			})
			if(result) {
				if(result.retour) return result.retour
			}
			return null
		},

		printCourrier: async function(model_ids, model_name, returnPDF = false, locale = null) {
			let localeString = locale ? "&locale=" + locale : ""
			const url = this.constructRoute(Constants.MODEL_PRINT_URL, {model_ids}) + "?licence_key=" + Constants.USER_LICENCE_KEY + localeString
			const result = await this.$request.request_get_api("ContractMixin::printCourrier", url)
			.catch(error => {
				console.error("ContractMixin::printCourrier => ERROR", error)
				return null
			})
			if(returnPDF) {
				return result
			}

			if(result) {
            	Common.base64ToPdf(result, model_name + ".pdf")
            	return true
			}

			return null
		},

		translateVariables(variables) {
			const translatedVar = []
			variables.forEach(variable => {
				variable.modeltypevariables_label = this.$t(variable.modeltypevariables_label)
				translatedVar.push(variable)
			})
			return translatedVar
		},

		translateBlocks(blocks) {
			const translatedBlocks = []

			blocks.forEach(block => {
				block.modeltypeblock_label = this.$t(block.modeltypeblock_label)
				block.variables = this.translateVariables(block.variables)
				block.children = this.translateBlocks(block.children)

				translatedBlocks.push(block)
			})

			return translatedBlocks
		},

		async deleteModel(models_ids) {
			const params = { 
				'models_ids': models_ids
			}

        	const url = Constants.MODEL_DELETE_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_delete_api("ModelMixin::deleteModel", url, params, false)
			.catch(error => {
				console.error("ModelMixin::deleteModel => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		async deleteSeasonModelById(model_id) {
			const url = this.constructRoute(Constants.MODEL_SEASON_URL, {model_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_delete_api("ModelMixin::deleteSeasonModelById", url, {}, false)
			.catch(error => {
				console.error("ModelMixin::deleteSeasonModelById => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		async getStallionModeleFromGlobal(model_id) {
			const url = this.constructRoute(Constants.MODEL_STALLION_URL, {model_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			
			const result = await this.$request.request_get_api("ModelMixin::getStallionModeleFromGlobal", url)
			.catch(error => {
				console.error("ModelMixin::getStallionModeleFromGlobal => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		async getStallionSeason(season_id, model_id) {
			const url = this.constructRoute(Constants.CONTRACT_CONFIG_SEASON_STALLION_URL, {season_id, model_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			
			const result = await this.$request.request_get_api("ContractMixin::getStallionSeason", url)
			.catch(error => {
				console.error("ModelMixin::getStallionSeason => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		saveDistributionSeasonModel: async function(models_ids, parent_model) {
			const url = this.constructRoute(Constants.MODEL_DISTRIBUTE_URL, {model_id: parent_model}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("ModelMixin::saveDistributionSeasonModel", url, {model_ids: models_ids}, false)
			.catch(error => {
				console.error("ModelMixin::saveDistributionSeasonModel => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		saveStallionToModeleParent: async function(parent_model, contract_config) {
			const url = this.constructRoute(Constants.MODEL_STALLION_URL, {model_id: parent_model}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("ModelMixin::saveStallionToModeleParent", url, {contract_config: contract_config}, false)
			.catch(error => {
				console.error("ModelMixin::saveStallionToModeleParent => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},


		archiveModel: async function(models_ids) {
			const params = { 
				'models_ids': models_ids
			}

			const number_models = models_ids.length

			const url = Constants.MODEL_ARCHIVE_URL + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("ModelMixin::archiveModel", url, params, false)
			.catch(error => {
				console.error("ModelMixin::archiveModel => ERROR", error)
				return null
			})
			if(result){
				if(number_models == result.retour.length) {
					this.successToast()
				}
				else{
					this.failureToast("toast.model_no_archive")
				}
				return result.retour
			}

			return null
		},

		getAllFormats() {
			let formats = [
				{
					id: 1,
					name: "C125101 - 70x35"
				},
				{
					id: 2,
					name: "C125101 - 105x148,5 - " + this.getTrad("horse.a6")
				},
				{
					id: 3,
					name: "C125101 - 148,5x105 - " + this.getTrad("horse.a6landscape")
				},
				{
					id: 4,
					name: "C125101 - 99,1x38,1"
				},
				{
					id: 5,
					name: "C125101 - 105x37"
				},
				{
					id: 6,
					name: "A4"
				},
				{
					id: 7,
					name: this.getTrad("horse.a4landscape")
				},
				{
					id: 8,
					name: this.getTrad("horse.a4saut")
				},
				{
					id: 9,
					name: "C125101 - 210x148,5 - " + this.getTrad("horse.a5landscape")
				},
				{
					id: 10,
					name: "C125101 - 148,5x210 - " + this.getTrad("horse.a5")
				},
			]

			return formats
		},

		getModelZone: async function(modelzone_id) {
			const url = this.constructRoute(Constants.MODEL_ZONE_URL, {modelzone_id: modelzone_id}) + "?licence_key=" + Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("ModelMixin::getModelZone", url)
			.catch(error => {
				console.error("ModelMixin::getModelZone => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		}
	}
}

export default ModelMixin
